import {LoggedUser} from './../../api/login'
export enum LoginTypes {
    LOGIN ="@@login/LOGIN",
    AFTER_LOGIN = "@@login/AFTER_LOGIN",
    LOGOUT = "@@login/LOGOUT",
    LOGIN_LOADING = "@@login/LOGIN_LOADING",
    LOGIN_ERROR = "@@login/LOGIN_ERROR",
    CLEAR_MESSAGE = "@@login/CLEAR_MESSAGE"
}
export interface LoginState {
    readonly loggedin: boolean,
    readonly user?: LoggedUser,
    readonly loading?: boolean,
    readonly errors?: string
}

export const createEmptyLoginState = (): LoginState => ({
    loggedin:false,
    loading: false
});
