import React from 'react';
import { FormGroup, Input, Label, Col } from 'reactstrap';
//import { FaSkullCrossbones } from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';

const ReduxFormInput: React.FC = (field: any) => (
    <FormGroup row={true} className="m-0 justify-content-center">
        <Col xs="3" lg="3" className="p-0">
            <Label size="sm">{field.label}</Label>
        </Col>
        <Col xs="3" lg="3">
            <Input
                {...field.input}
                type={field.type}
                placeholder={field.placeHolder}
                max={field.maxDate}
                min={field.minDate}
                step={field.step}
                disabled={field.disabled}
                size="sm"
            />
        </Col>
        <Col xs="2" lg="2">
            <div>{field.meta.touched && field.meta.error ? <p className="text-danger"><GoAlert size={20} /></p> : <p></p>}</div>
        </Col>
    </FormGroup>
);

export default ReduxFormInput;
